import React, {useState} from "react"
import {langTextData} from './textData'
import {apiUrl, req} from 'api-methods'

function App() {
    const [apiMessage, setApiMessage] = useState('')
    const [apiMessageColor, setApiMessageColor] = useState('')
    const [apiMessageClose, setApiMessageClose] = useState(false)
    const [image, setImage] = useState()
    const [imageURL, setImageURL] = useState('')
    const fileReader = new FileReader();fileReader.onloadend = () => {setImageURL(fileReader.result)}
    const handleOnChange = (event) => {event.preventDefault();if (event.target.files && event.target.files.length) {const file = event.target.files[0];setImage(file);fileReader.readAsDataURL(file);}}
    let lang = langTextData.defaultLang
    let fileFormat = ['xls','xlsx']

    async function manualUpload (event) {
        if (!image.name || !image.name.split('.')[1] || (fileFormat).indexOf(image.name.split('.')[1].toLowerCase()) === -1) {
            setApiMessageColor('fileUploader__message-textRed')
            setApiMessage(langTextData[lang].outMessage.err)
        } else {
            const formData = new FormData()
            formData.append('method', event)
            formData.append('report', image)
            setApiMessageColor('fileUploader__message-textYellow')
            setApiMessage(langTextData[lang].outMessage.pro)

            if (imageURL !== '') {
                document.getElementById("upload_" + event).value = ""

                await req({method: apiUrl.fUpload, options: {method: 'POST', body: formData}, form: 'form'}).then(data => {
                    if (data.code === 0) {setApiMessageColor('fileUploader__message-textGreen');setApiMessage(langTextData[lang].outMessage.god)}
                    else if (data.code !== 0 && data.msg !== '') {setApiMessageColor('fileUploader__message-textRed');setApiMessage(data.msg)}
                    else {setApiMessageColor('fileUploader__message-textRed');setApiMessage(langTextData[lang].outMessage.err)}
                })
            }
        }
    }

    return (
        <div className="App">
            <div className="all_width_for_file">
                <div className="all_width_upload">
                    {(apiMessage !== '' && !apiMessageClose) && (<div className="fileUploader__message"><div className={apiMessageColor}><div className="fileUploader__message-text">{apiMessage}</div><div className="fileUploader__message-close" onClick={e => setApiMessageClose(true)}>×</div></div></div>)}

                    <div className="shadow fileUploader__inputFile-block">
                        <div className="fileUploader__inputFile-title">{langTextData[lang].formFileLoad.firstLoad.title}</div>
                        <input type="file" id="upload_activate" accept={(fileFormat).join(" ")} className="fileUploader__inputFile-file" onChange={e => handleOnChange(e)}/><br /><br />
                        <button className="fileUploader__inputFile-button" onClick={e => manualUpload('activate')}>{langTextData[lang].formFileLoad.firstLoad.button}</button>
                    </div>

                    <div className="shadow fileUploader__inputFile-block">
                        <div className="fileUploader__inputFile-title">{langTextData[lang].formFileLoad.noFirstLoad.title}</div>
                        <input type="file" id="upload_suspend" accept={(fileFormat).join(" ")} className="fileUploader__inputFile-file" onChange={e => handleOnChange(e)}/><br /><br />
                        <button className="fileUploader__inputFile-button" onClick={e => manualUpload('suspend')}>{langTextData[lang].formFileLoad.noFirstLoad.button}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default App;
