import React, {useState} from "react"
import ULinkP from "./uLinkP"
import {uSearch, urlGuidSearch, ajaxUpdate} from "helper-func"
import {apiLinkGuid, apiLinkGuidCheckCp} from 'api-methods'

function App() {

    // 1. Params
    let guid = urlGuidSearch(1, ['/d/','/download/'])
    const [showProducts, setShowProducts] = useState(false)
    const [reqOne, setReqOne] = useState(false)
    const [keyMenu, setKeyMenu] = useState(uSearch('os') ? uSearch('os') : 'Windows')
    const [loader, setLoader] = useState(true)
    const [notFound, setNotFound] = useState(true)

    // 2. req data
    async function getData () {
        return await apiLinkGuid(guid).then(data => {
            if (data.data) {
                apiLinkGuidCheckCp(data)
                setShowProducts(data.data)
                setNotFound(false)
            }
            setLoader(false)
        })
    }

    // 3. Change All
    if (!reqOne) {
        if (guid !== '') {
            setReqOne(true)
            getData().then()
        } else {
            setLoader(false)
        }
    }

    // 4. Click Menu
    function onItemClick (props) {setKeyMenu(props)}

    // 1.4. clear, resize, ajaxUpdate ↓
    if (document.location.hash === '#postmessage') {
        const [resizeValue, setResizeValue] = useState(800)
        function heightSend () {
            let actualPageSize = document.body.clientHeight + 40
            if (resizeValue !== actualPageSize) {
                setResizeValue(actualPageSize)
                window.parent.postMessage({height: actualPageSize}, '*')
            }
        }
        const [resizeEvent, setResizeEvent] = useState(false)
        if (!resizeEvent) {
            setResizeEvent(true)
            window.addEventListener('resize', function(event) {heightSend()}, true)
        }
        setTimeout(() => {heightSend()}, ajaxUpdate)
    }
    // 1.4. clear, resize, ajaxUpdate ↑

    return (
        <div className="App">
            {(loader ? <div className="preLoader"><div className="loader" /></div> : (notFound ? <div className="notFound">404</div> : <div>
                <div id="header" className="header shadow all_width"><div className="header__logo">CPP Turkey and Kaspersky LAB</div></div>

                {showProducts && <div id="menu" className="menu shadow all_width">
                    <div className="custom-radio">
                        {(['Android','Windows','iOS','MacOS']).map((item, key) => {
                            return <div key={key}><label><input name="radio" type="radio" defaultChecked={(item === keyMenu)} onClick={() => onItemClick(item)}/><div>{item}</div></label></div>
                        })}
                    </div>
                </div> }

                {<div id="content" className="content all_width">
                    {!showProducts
                        ? <div className="notFound">404</div>
                        : <label>
                            {(['kts','kpm','ksk','kis']).map((item, key) => {
                                if (showProducts[keyMenu][item]) {
                                    return <ULinkP key={key} namex={item} url={showProducts[keyMenu][item]}/>
                                }
                                return null
                            })}
                        </label>}
                </div>}

            </div>))}
        </div>
    )
}

export default App
