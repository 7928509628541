import React from "react"

function ULinkP(props) {

    const products = {
        kts: {
            name: 'Kaspersky Total Security',
            title: ['Kaspersky Total Security sizi virüslere, fidye yazılımlarına, kimlik avına ve kimlik\n' +
            'hırsızlığına karşı korurken mobil cihazlarda gezinebilir, sosyalleşebilir ve alışveriş\n' +
            'yapabilirsiniz.'],
            list: [
                'Saldırılara, fidye yazılımlarına ve daha fazlasına karşı korur',
                'Gizli bilgilerinizin gizli kalmasına yardımcı olur',
                'Çevrimiçi banka ve alışveriş işlemlerinde güvenliği artırır',
                'Siz "hareket halindeyken" mobil antivirüs ve güvenlik',
                'Çevrimiçi olabildiğiniz her yerden yönetmenizi kolaylaştırır',
                'Daima uzman teknik destek sağlanır.'
            ],
        },
        kpm: {
            name: 'Kaspersky Password Manager',
            title: [
                'Birden çok hesap için tek bir parola kullanmak risklidir. Ancak birden çok parolayı hatırlamak daha risklidir.',
                'Kaspersky Parola Yöneticisi tüm parolaları güvenli bir şekilde depolar. Tüm parolalarınıza erişmek için tek bir ana parolayı hatırlamanız yeterlidir.'
            ],
            list: [
                'Parolaları, kart numaralarını ve daha fazlasını depolayın',
                'PC, Mac, mobil ve My Kaspersky yoluyla erişin',
                'Web siteleri ve hesaplarda oturum açmayı kolaylaştırın.'
            ],
        },
        ksk: {
            name: 'Kaspersky Safe Kids',
            title: [
                'Kaspersky Safe Kids çocuklarınızın dijital dünyayı güvenli bir şekildekeşfetmesini sağlar.',
                'Bunu cihaz kullanma programı, şüpheli aktiviteler ile ilgili otomatik bildirimler, GPS güvenli alanlar ve ne aradıklarının,gördüklerinin ve paylaştıklarının özeti ile yapar.',
                'Bu şekilde çocuklarınıza bağlantıda kalır ve onları güvende tutabilirsiniz.'
            ],
            list: [

            ],
        },
        kis: {
            name: 'Kaspersky Internet Security',
            title: [
            ],
            list: [

            ],
        },
    }

    let icon = 'logo_' + props.namex

    return (
        <div className="content__item shadow">
            <div className={icon} />
            <div className="content__item-name">{products[props.namex].name}</div>
            <div className="content__item-title">
                {products[props.namex].title.map(function(item, i){return <div key={i}>{item}</div>})}
                {<ul>{products[props.namex].list.map(function(item, i){return <li key={i}>{item}</li>})}</ul>}
            </div>
            <div className="content__item-download">
                <a href={props.url} target="_blank"><div className="content__item-download_link">DOWNLOAD</div></a>
            </div>
        </div>
    )
}

export default ULinkP
