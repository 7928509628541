
export const langTextData = {
    defaultLang: 'tr',
    tr: {
        outMessage: {
            err: 'File upload error',
            pro: 'Processing ...',
            god: 'Dosya başarılı şekilde yüklendi. İşleme sonuçları için lütfen e-postanızı kontrol edin.',
        },
        formFileLoad: {
            firstLoad: {
                title: 'Yeni üyelik için XLS dosyasını yükleyin',
                button: 'Yeni üyelikleri yükleyin',
            },
            noFirstLoad: {
                title: 'İptal edilmiş üyelik için XLS dosyasını yükleyin',
                button: 'İptal edilmiş üyelikleri yükleyin',
            },
        }
    }
}
