import React from "react"
import {Routes, Route} from "react-router-dom"
import ULink from "./uLink"
import Upload from "./uUpload"
import './App.css'
import {urlAlert} from "api-methods"

function App() {

    if (document.location.pathname.replace(/[/_A-Za-z0-9.%]+/g, "") !== "") {urlAlert('url-pathname-char').then()}
    if (document.location.search.replace(/[?=&A-Za-z0-9]+/g, "") !== "") {urlAlert('url-search-char').then()}

    return <Routes>
        <Route path="/download/*" element={<ULink />} />
        <Route path="/d/*" element={<ULink />} />
        <Route path="/actions/*" element={<Upload />} />
        <Route path="*" element={<div className="notFound">404</div>}/>
    </Routes>
}

export default App
